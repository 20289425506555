<script>
import ProductIntro from '@/components/pages/ProductDetail/ProductIntro.vue'
import ProductDescription from '@/components/pages/ProductDetail/ProductDescription.vue'
export default {
  components: { ProductIntro, ProductDescription },
  data() {
    return {
      product: {},
    }
  },
  async created() {
    const data = await this.$store.dispatch('getSingleProduct', {
      url: `products/${this.$route.params.id}`,
    })
    if (data)
      this.product = data
  },
}
</script>

<template>
  <div class="productDetail__container">
    <div class="productDetail">
      <ProductIntro :product="product" />
      <ProductDescription :product="product" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
