<script>
export default {
  props: ['data'],
}
</script>

<template>
  <div class="productDetail__productDescription__categoriestab">
    <p v-if="data.text" class="productDetail__productDescription__tabsDescText" v-html="data.text" />
  </div>
</template>

<style lang="scss" scoped>

</style>
