<script>
import ProductDetail from '@/components/pages/ProductDetail.vue'
export default {
  components: {
    ProductDetail,
  },
}
</script>

<template>
  <div>
    <ProductDetail />
  </div>
</template>
