<script>
import CartIcon from '@/assets/svgs/ButtonCartIcon.vue'
import FbIcon from '@/assets/svgs/fb2.vue'
import InstaIcon from '@/assets/svgs/insta2.vue'

export default {
  components: { CartIcon, InstaIcon, FbIcon },
  props: ['product'],
  data() {
    return {
      mouseon: false,
      miniBottle: require('../../../assets/images/productdetail/mini.png'),
      largeBottle: require('../../../assets/images/productdetail/large.png'),
      logo: require('../../../assets/images/productdetail/LogoAS.svg'),
      mobileimg: require('../../../assets/images/productdetail/mobile.png'),
      quantity: 1,
    }
  },

  methods: {
    adjustProductQty(type) {
      if (type == 'increase')
        this.quantity++
      else if (type == 'decrease')
        this.quantity--
    },
    addProductToCart() {
      const prod = { ...this.product }

      const quantity = this.quantity
      const payload = {
        id: prod.id,
        title: prod.nameFrench,
        price: prod.price,
        image: prod.images[0].content,
        quantity,
        sum: prod.price,
      }

      // this.$store.commit("adjustProductQty", {
      //   type,
      //   product: this.product,
      // });
      this.$store.commit('addProductToCart', payload)
    },
  },
}
</script>

<template>
  <div class="productDetail__productIntro">
    <div class="productDetail__productIntro__container">
      <div v-if="product.images" class="row">
        <div class="col-lg-6 col-md-5 col-sm-4 col-12">
          <div
            class="
              d-sm-flex d-none
              align-items-center
              justify-content-md-start
              justify-content-sm-between
              justify-content-center
            "
          >
            <div class="productDetail__productIntro__leftImages">
              <div class="productDetail__productIntro__logo">
                <img class="img-fluid" :src="logo" alt="">
              </div>
              <div class="productDetail__productIntro__minibottle">
                <img
                  class="img-fluid" :src="`data:image/jpg;base64,${product.images[0].content}`"
                  alt=""
                >
              </div>
            </div>
            <div class="productDetail__productIntro__largebottle">
              <img class="img-fluid" :src="`data:image/jpg;base64,${product.images[0].content}`" alt="">
            </div>
          </div>
          <div
            class="
              productDetail__productIntro__mobileImgContainer
              text-center
              d-flex d-sm-none
              w-100
              align-items-center
              justify-content-around
            "
          >
            <img
              class="
                img-fluid
                productDetail__productIntro__mobileImgContainer__img
                small
              " :src="`data:image/jpg;base64,${product.images[0].content}`" alt=""
            >
            <img
              v-if="product.images[0].content" class="
                img-fluid
                productDetail__productIntro__mobileImgContainer__img
              " :src="`data:image/jpg;base64,${product.images[0].content}`" alt=""
            >
            <img
              v-if="product.images[0].content" class="
                img-fluid
                productDetail__productIntro__mobileImgContainer__img
                small
              " :src="`data:image/jpg;base64,${product.images[0].content}`" alt=""
            >
          </div>
        </div>
        <div
          class="
            col-lg-6 col-md-7 col-sm-8 col-12
            text-center text-sm-left
            productDetail__productIntro__bottomColumn
          "
        >
          <h1 class="productDetail__productIntro__title text-uppercase">
            {{ product.nameFrench }}
          </h1>
          <p class="productDetail__productIntro__size text-uppercase">
            {{ product.lightDescriptionFrench }}
          </p>
          <div
            class="
              d-sm-block d-flex
              justify-content-center
              align-items-baseline
            "
          >
            <small class="productDetail__productIntro__ttc">TTC</small>
            <h1 class="productDetail__productIntro__price">
              <span>{{ product.price.toFixed(2) }}</span>
              <span class="ml-sm-2 productDetail__productIntro__price__currency">Dhs</span>
            </h1>
          </div>
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              productDetail__productIntro__quantityContainer
            "
          >
            <button
              :disabled="quantity == 1" class="btn p-0 productDetail__productIntro__quantitybtn"
              @click="adjustProductQty('decrease')"
            >
              -
            </button>
            <p class="productDetail__productIntro__quantity">
              {{ quantity }}
            </p>
            <button
              class="btn p-0 productDetail__productIntro__quantitybtn"
              @click="adjustProductQty('increase')"
            >
              +
            </button>
          </div>

          <div
            class="
              d-flex
              justify-content-sm-between justify-content-center
              align-items-center
              productDetail__productIntro__buttonContainer
            "
          >
            <button
              class="
                btn
                productDetail__productIntro__btn
                d-flex
                align-items-center
                justify-content-between
              " @mouseenter="mouseon = true" @mouseleave="mouseon = false" @click="addProductToCart"
            >
              <div class="productDetail__productIntro__btn__iconContainer" :class="{ move: mouseon }">
                <CartIcon color="#ffff" />
              </div>

              <p class="productDetail__productIntro__btn__text text-uppercase">
                ajouter
              </p>
            </button>
            <div
              class="
                productDetail__productIntro__socialContainer
                d-sm-flex d-none
                align-items-center
              "
            >
              <p
                class="
                  my-0
                  productDetail__productIntro__socialContainer__text
                  text-uppercase
                "
              >
                Partager
              </p>
              <div class="mx-3">
                <a target="_blank" href="https://www.facebook.com/AinSaissOfficiel/">
                  <FbIcon />
                </a>
              </div>
              <div>
                <a target="_blank" href="https://www.instagram.com/ainsaissofficiel/">
                  <InstaIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
