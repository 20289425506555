<script>
export default {}
</script>

<template>
  <svg
    id="Component_20_25" data-name="Component 20 – 25" xmlns="http://www.w3.org/2000/svg" width="20.997"
    height="20.997" viewBox="0 0 20.997 20.997"
  >
    <path
      id="Path_3302" data-name="Path 3302"
      d="M736.244,597.368h-9.681a5.665,5.665,0,0,0-5.658,5.658v9.681a5.665,5.665,0,0,0,5.658,5.658h9.681a5.665,5.665,0,0,0,5.658-5.658v-9.681A5.665,5.665,0,0,0,736.244,597.368Zm4.022,15.339a4.026,4.026,0,0,1-4.022,4.022h-9.681a4.026,4.026,0,0,1-4.022-4.022v-9.681A4.026,4.026,0,0,1,726.563,599h9.681a4.026,4.026,0,0,1,4.022,4.022Z"
      transform="translate(-720.905 -597.368)" fill="#253a93"
    />
    <path
      id="Path_3303" data-name="Path 3303"
      d="M737.005,607.988a4.692,4.692,0,1,0-.7,9.334,4.721,4.721,0,0,0,.7-.052,4.691,4.691,0,0,0,0-9.281Zm1.766,6.46a3.055,3.055,0,1,1-2.9-4.842,3.1,3.1,0,0,1,.9,0,3.04,3.04,0,0,1,2.574,2.573h0A3.038,3.038,0,0,1,738.771,614.449Z"
      transform="translate(-725.777 -602.171)" fill="#253a93"
    />
    <path
      id="Path_3304" data-name="Path 3304"
      d="M748.65,605.6a.824.824,0,0,0-.24.578.818.818,0,0,0,1.4.578.817.817,0,0,0,.24-.578.826.826,0,0,0-.24-.578A.843.843,0,0,0,748.65,605.6Z"
      transform="translate(-732.992 -601.004)" fill="#253a93"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
