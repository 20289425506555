<script>
export default { props: ['data'] }
</script>

<template>
  <div class="productDetail__productDescription__compositiontab">
    <p v-if="data.text" class="productDetail__productDescription__tabsDescText" v-html="data.text" />

    <!-- <p
      class="productDetail__productDescription__tabsDescText"
      v-if="data.listTitle"
    >
      {{ data.listTitle }}
    </p> -->
    <!-- <ul v-if="data.list">
      <li v-for="item in data.list" :key="item.title">
        <p>
          <span
            class="productDetail__productDescription__compositiontab__listTitle"
            >{{ item.title }} :
          </span>
          <span
            class="productDetail__productDescription__compositiontab__listValue ml-2"
            >{{ item.value }}</span
          >
        </p>
      </li>
    </ul> -->
  </div>
</template>

<style lang="scss" scoped>

</style>
