<script>
export default {}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.127" height="20.997" viewBox="0 0 21.127 20.997">
    <path
      id="Path_3301" data-name="Path 3301"
      d="M673.58,597.243a10.563,10.563,0,0,0-1.649,21v-7.382h-2.683V607.8h2.683v-2.327c0-2.648,1.576-4.11,3.989-4.11a16.317,16.317,0,0,1,2.365.2v2.6h-1.332a1.527,1.527,0,0,0-1.721,1.65v1.982h2.93l-.469,3.053h-2.462v7.381a10.563,10.563,0,0,0-1.652-21Z"
      transform="translate(-663.018 -597.243)" fill="#253a93"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
