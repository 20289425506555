<script>
import { BCollapse } from 'bootstrap-vue'
import FORMAT from '@/components/pages/ProductDetail/ProductDescriptionComponents/Description.vue'
import DESCRIPTION from '@/components/pages/ProductDetail/ProductDescriptionComponents/Composition.vue'
import CONSERVATION from '@/components/pages/ProductDetail/ProductDescriptionComponents/Conservation.vue'
import CATÉGORIES from '@/components/pages/ProductDetail/ProductDescriptionComponents/Categories.vue'

export default {
  components: { FORMAT, DESCRIPTION, CONSERVATION, CATÉGORIES, BCollapse },
  props: ['product'],
  data() {
    return {
      titles: ['FORMAT', 'DESCRIPTION', 'CONSERVATION', 'CATÉGORIES'],
      active: 'FORMAT',
      activeData: {},
      tabs: [
        {
          title: 'FORMAT',
          text: '',
        },
        {
          title: 'DESCRIPTION',
          text: '',
          // listTitle: "Composition de l’eau en mg/L:",
          // list: [
          //   { title: "Calcium", value: 70 },
          //   { title: "Magnésium", value: 40 },
          //   { title: "Potassium", value: 8 },
          //   { title: "Sulfate", value: 20 },
          //   { title: "Nitrate", value: 4 },
          //   { title: "Chlorures", value: 200 },
          //   { title: "Sodium", value: 120 },
          //   { title: "Bicarbonate", value: 335 },
          // ],
        },
        {
          title: 'CONSERVATION',
          text: 'A conserver à l’abri du soleil et des odeurs.',
        },
        {
          title: 'CATÉGORIES',
          text: '',
        },
      ],
    }
  },
  computed: {
    getproduct() {
      return this.product
    },
  },
  watch: {
    getproduct(prod) {
      const tabData = [...this.tabs]

      const titles = [...this.titles]

      this.tabs = tabData.filter((tab) => {
        if (tab.title == titles[0]) {
          tab.text = prod.lightDescriptionFrench
        }
        else if (tab.title == titles[1]) {
          tab.text = prod.detailedDescriptionFrench
        }
        else if (tab.title == titles[3]) {
          tab.text = ''
          for (const cat of prod.categories)
            tab.text += `${cat.nameFrench}<br>`

          console.log(tab.text)
        }
        return tab
      })

      this.activeData = this.tabs[0]
    },
  },
  methods: {
    setActiveComponent(title) {
      this.active = title
      this.activeData = this.tabs.find(
        tab => tab.title.trim() == title.trim(),
      )
    },
  },
}
</script>

<template>
  <div class="productDetail__productDescription">
    <div class="productDetail__productDescription__container d-lg-block d-none">
      <div class="productDetail__productDescription__tabs d-flex justify-content-center ">
        <button
          v-for="title in titles" :key="title"
          class="btn p-0 productDetail__productDescription__tabs__box" @click="setActiveComponent(title)"
        >
          <h1
            class="productDetail__productDescription__tabs__heading text-uppercase"
            :class="{ inactive: title !== active }"
          >
            {{ title }}
          </h1>
          <div class="productDetail__productDescription__tabs__bar" :class="{ inactive: title !== active }" />
        </button>
      </div>
      <div class="productDetail__productDescription__tabContainer">
        <transition name="fade" mode="out-in">
          <component :is="active" :key="active" :data="activeData" />
        </transition>
      </div>
    </div>
    <div class="d-lg-none d-block ">
      <div class="d-flex flex-column">
        <div v-for="title in titles" :key="title" class="productDetail__productDescription__mobileContent">
          <button
            class="btn p-0 productDetail__productDescription__tabs__box d-block mx-auto"
            @click="setActiveComponent(title)"
          >
            <h1
              class="productDetail__productDescription__tabs__heading text-uppercase"
              :class="{ inactive: title !== active }"
            >
              {{ title }}
            </h1>
            <div
              class="productDetail__productDescription__tabs__bar"
              :class="{ inactive: title !== active }"
            />
          </button>
          <BCollapse :visible="title == active" :accordion="title" role="tabpanel" class="pt-4">
            <component :is="active" :data="activeData" />
          </BCollapse>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
