<script>
export default { props: ['data'] }
</script>

<template>
  <div class="productDetail__productDescription__descriptiontab">
    <p
      v-if="data.text"
      class="productDetail__productDescription__descriptiontab__text productDetail__productDescription__tabsDescText" v-html="data.text"
    />
  </div>
</template>

<style lang="scss" scoped>

</style>
